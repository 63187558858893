/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"

import { GlobalStyle } from "./globalStyles"
import Header from "./header"

const Container = styled.div`
  margin: 50px 100px auto 100px;
  padding: 0 1.0875rem 1.45rem;
`

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <GlobalStyle />
      {location.pathname === "/" && (
        <Header siteTitle={data.site.siteMetadata.title} />
      )}
      <Container>
        <main>{children}</main>
        <footer>
          {/* © {new Date().getFullYear()} Composite Financial Inc.  */}
        </footer>
      </Container>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
