import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"

import Image from "../components/image"

const StyledHeader = styled.header`
  margin-bottom: 1.45rem;
  margin: 0 auto;
  max-width: 960px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const LogoContainer = styled.div`
  margin: 0 auto;
  max-width: 50%; // update this
  width: 100%;
  height: 100%;
`

const Header = ({ siteTitle }) => (
  <StyledHeader>
    <LogoContainer>
      <Link
        to="/"
        style={{
          color: `white`,
          textDecoration: `none`,
        }}
      >
        <Image />
      </Link>
    </LogoContainer>
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
